.MuiDataGrid-root
  .MuiDataGrid-columnHeader
  .MuiDataGrid-columnHeaderTitleContainerContent {
  flex-grow: 1;
}
.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiButtonBase-root {
  padding: 4px;
  box-shadow: none;
}
.MuiDataGrid-root
  .MuiTablePagination-root
  .MuiTablePagination-actions
  .MuiIconButton-root {
  margin: 0 4px;
  box-shadow: black 1px 1px;
  width: 32px;
  height: 32px;
}
