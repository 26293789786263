.sx-react-calendar-wrapper {
  height: 800px;
  max-height: calc(100vh - 110px);
}

.sx__month-grid-day__events .sx__month-grid-cell {
  width: 100% !important;
  height: auto !important;
  overflow: auto !important;
  justify-content: center;
  padding: 8px 4px !important;
  white-space: normal;
}

.planning-card .open {
  color: #eb8938;
}

.planning-card .closed {
  color: #34b864;
}

.sx__date-picker-wrapper {
  display: none;
}

.sx__forward-backward-navigation {
  display: block !important;
}
