.react-daterange-picker__wrapper {
  padding: 7px;
  border-radius: 36px;
}

.mui-dark .react-daterange-picker__wrapper svg {
  stroke: white;
}

.react-calendar {
  border-radius: 8px;
  overflow: hidden;
}

.react-daterange-picker__button:enabled:hover
  .react-daterange-picker__button__icon,
.react-daterange-picker__button:enabled:focus
  .react-daterange-picker__button__icon {
  stroke: #00fd7a;
}
