.MuiChip-root.default {
  color: #91918e;
  border-color: #91918e;
}
.MuiChip-root.brown {
  color: #bb846c;
  border-color: #bb846c;
}
.MuiChip-root.orange {
  color: #eb8938;
  border-color: #eb8938;
}
.MuiChip-root.green {
  color: #34b864;
  border-color: #34b864;
}
.MuiChip-root.blue {
  color: #5b97bd;
  border-color: #5b97bd;
}
.MuiChip-root.red {
  color: #d32f2f;
  border-color: #d32f2f;
}
.MuiChip-root.purple {
  color: #79449c;
  border-color: #79449c;
}
