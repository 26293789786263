.reporting-tab .tab-nav {
  display: flex;
  user-select: none;
}

.reporting-tab .nav {
  padding: 12px;
  padding-bottom: 24px;
  min-width: 120px;
  border: solid 1px rgba(27, 31, 35, 0.15);
  background: white;
  cursor: pointer;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  border-bottom: none;
  position: relative;
}

.reporting-tab .nav:not(:first-child) {
  margin-left: -1px;
}

.reporting-tab .nav.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 16px;
  background-color: white;
  z-index: 1;
}

.reporting-tab .tab-body {
  background: white;
  border: solid 1px rgba(27, 31, 35, 0.15);
  padding: 16px;
  border-radius: 16px;
  margin-top: -14px;
  position: relative;
}

/*DARK mode*/
.mui-dark .reporting-tab .nav,
.mui-dark .reporting-tab .tab-body,
.mui-dark .reporting-tab .nav.active::after {
  background: #27252d;
}

.mui-dark .reporting-tab .nav,
.mui-dark .reporting-tab .tab-body {
  background: #27252d;
  border: solid 1px rgba(253, 254, 255, 0.15);
}

@media screen and (max-width: 680px) {
  .tab-nav .nav {
    min-width: auto;
  }
  .tab-nav .nav h3 {
    display: none;
  }
  .tab-nav .nav.active h3 {
    display: block;
  }
}
